<template>
    <div>
        <div style="padding:5px;">
            <el-row>
                <el-col :span="4"></el-col>
                <el-col :span="6">
                    <div style="text-align:left;margin-bottom: 5px;">
                        <el-radio-group v-model="search_type" @change="handleSearchTypeChange">
                            <el-radio :label="1">按关键字搜索</el-radio>
                            <el-radio :label="2">按坐标搜索</el-radio>
                        </el-radio-group>
                    </div>
                    <div style="display:flex;">
                        <el-input :placeholder="placeholder" v-model="address" label="关键字 " size="small"
                            @keyup.enter="searchAddress" clearable></el-input>
                        <el-button style="margin-left:5px;" type="primary" icon="el-icon-search" @click="searchAddress"
                            size="small">查询</el-button>
                    </div>
                </el-col>
                <el-col :span="2"></el-col>
                <el-col :span="2"></el-col>
                <el-col :span="6">
                    <div style="text-align:left;margin-bottom: 5px;">坐标获取结果:</div>
                    <div style="display:flex;">
                        <el-input v-model="location" disabled size="small"></el-input>
                        <el-button style="margin-left:5px;" type="primary" icon="el-icon-document-copy"
                            @click="handleCopy" size="small">复制</el-button>
                    </div>
                </el-col>
                <el-col :span="4">

                </el-col>
            </el-row>

        </div>
        <div style="position:relative;height:82vh;border: 2px solid #3d6eff;">
            <div class="container">
                <show-location style="width: 100%; height: 100%" ref="map" drag @change="handleLocationChange">
                </show-location>
            </div>
        </div>
    </div>
</template>

<script>


import { ShowLocation } from "@/components/map/components";
import axios from 'axios'
export default {
    components: { ShowLocation },
    setup() {

    },
    data() {
        return {
            ak: 'dc790cc4063785169ca49b8378f5a3a9',
            search_type: 1,
            placeholder: '输入关键字进行搜索',
            address: '',
            location: undefined,

        }

    },
    mounted() {

    },
    methods: {
        init() {
            this.map = this.$refs.ov_olmap.map;
            console.log(this.map);
            //定位
            this.generateIconLayer();
            this.map.addLayer(this.iconLayer);
            if (this.iconLayer) {
                this.iconFeature = this.genereateLocationFeature();
                this.iconLayer.getSource().addFeature(this.iconFeature);
                // this.toCenter(this.location, 15);
            }

            //拖拽事件
            if (this.iconDrag == undefined) {
                this.initMapDrag();
            }
            this.map.addInteraction(this.iconDrag);

        },
        copyText(data, el) {

            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select();// 选择对象
            document.execCommand("Copy");// 执行浏览器复制命令
            el.$message({
                message: '复制成功',
                type: 'success'
            });
            oInput.remove()
        },
        handleSearchTypeChange() {
            switch (parseInt(this.search_type)) {
                case 1:
                    this.address = '';
                    this.placeholder = '输入关键字进行搜索';
                    break;
                case 2:
                    this.address = '';
                    this.placeholder = '输入坐标进行搜索';
                    break;
            }
        },
        searchAddress() {

            switch (parseInt(this.search_type)) {
                case 1:
                    this.geoSearch();
                    break;
                case 2:
                    this.regeoSearch();
                    break;
            }

        },
        geoSearch() {
            let url = 'https://restapi.amap.com/v3/geocode/geo';
            axios.get(url, {
                params: {
                    key: this.ak,
                    address: this.address,
                    output: 'json',
                }

            }).then(response => {
                const { data } = response;
                const { status, geocodes } = data;
                if (status != 1 && geocodes.length <= 0) {
                    return;
                }
                this.location = geocodes[0].location;

                this.$refs.map.showLocation(this.location);
            })
        },
        regeoSearch() {
            let url = 'https://restapi.amap.com/v3/geocode/regeo';
            axios.get(url, {
                params: {
                    key: this.ak,
                    location: this.address,
                    output: 'json',
                }

            }).then(response => {
                const { data } = response;
                const { status, regeocode } = data;
                if (status != 1) {
                    return;
                }
                this.location = regeocode.addressComponent.streetNumber.location;
                this.$refs.map.showLocation(this.location);
            })

        },
        handleCopy() {
            this.copyText(this.location, this);
        },
        handleLocationChange(location) {
            this.location = location;
            this.$refs.map.showLocation(this.location);
        },


    }
}
</script>


<style scoped>
.container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0
}
</style>